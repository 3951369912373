// @flow
import { Record } from 'immutable';
import type {
  InstructorI,
  RegistrationQuestionI
} from 'redux/interfaces/api/OccurrenceI';

export type DateI = {
  isSingleDay: boolean,
  startTime: string,
  endTime: string
};

export type EntityProps = {
  type: string,
  id: string,
  occurrenceId: string,
  occurrenceSlug: string,
  instructorId: string,
  instructorSlug: string,
  name: string,
  price: number,
  adjustedPrice: number,
  capacity: number,
  numberOfOpenSeats: number,
  isPast: boolean,
  canPurchase: boolean,
  canRegister: boolean,
  isRetailPartner: boolean,
  organizationId: string,
  venue: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  curriculumCode: string,
  isCiLevel: boolean,
  hasTangibleGoods: boolean,
  isPromo: boolean,
  dates: Array<DateI>,
  isEligibleForInstructorAutomation: boolean,
  willAssignPrerequisiteELearning: boolean,
  registrationQuestions: Array<RegistrationQuestionI>,
  willShipTangibleGoods: boolean,
  originatingSiteUrl: string,
  instructors: Array<InstructorI>,
  customConfirmationMessage: string,
  orgDisplayName: string,
  orgShortDisplayName: string
};

export type EntityRecordI = {
  toJS: () => EntityProps
} & EntityProps;

const EntityRecord = Record({
  type: '',
  id: '',
  occurrenceId: '',
  occurrenceSlug: '',
  instructorId: '',
  instructorSlug: '',
  name: '',
  price: 0,
  adjustedPrice: 0,
  capacity: 0,
  numberOfOpenSeats: 1,
  isPast: false,
  canPurchase: false,
  canRegister: false,
  isRetailPartner: false,
  organizationId: '',
  venue: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  curriculumCode: '',
  isCiLevel: false,
  hasTangibleGoods: false,
  isPromo: false,
  dates: [],
  isEligibleForInstructorAutomation: false,
  willAssignPrerequisiteELearning: false,
  registrationQuestions: [],
  willShipTangibleGoods: false,
  originatingSiteUrl: '',
  instructors: [],
  customConfirmationMessage: '',
  orgDisplayName: '',
  orgShortDisplayName: ''
});

export default EntityRecord;
