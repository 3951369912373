// @flow
import { handleActions } from 'redux-actions';

import { APP_NAMESPACE } from 'shared/constants/application';
import type { ActionI } from 'redux/interfaces/ActionI';
import { SET_SOURCE, BUY_CLASS_SUCCESS } from '../payment/payment';
import { REGISTER_SUCCESS } from '../register/register';
import { SET as SET_COURSE } from '../course/courseActions';
import { SET as SET_OCCURRENCE } from 'redux/modules/occurrence/occurrenceActions';
import type { SetOccurrenceI } from 'redux/modules/occurrence/occurrenceActionCreators';
import { FETCH_SUCCESS as SET_USER } from 'redux/modules/user/userActions';
import type { ReceiveUserReturn } from 'redux/modules/user/userActionCreators';
import type { SetCourseI } from '../course/courseActionCreators';
import { parseCourseJsonToEntity } from '../entity/parseCourseJsonToEntity';
import type {
  RegistrationAnswerI,
  RegistrationQuestionI
} from 'redux/interfaces/api/OccurrenceI';
import validate from './validate';

const namespace: string = `${APP_NAMESPACE}/checkout`;

// Actions
export const NEXT_STEP: string = `${namespace}/STEP/NEXT`;
export const PREV_STEP: string = `${namespace}/STEP/PREV`;
export const SET_STEP: string = `${namespace}/STEP/SET`;
export const VALIDATE_FORM: string = `${namespace}/FORM/VALIDATE`;
export const SET_BLURRED: string = `${namespace}/SET_BLURRED`;
export const SET_VALUE: string = `${namespace}/SET_VALUE`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Action Creators
export const nextStep = (): ActionI => ({
  type: NEXT_STEP
});

export const prevStep = (): ActionI => ({
  type: PREV_STEP
});

export const validateForm = (): ActionI => ({
  type: VALIDATE_FORM
});

type SetStepI = {
  payload: {
    step: number
  }
} & ActionI;

export const setStep = (step: number): SetStepI => ({
  type: SET_STEP,
  payload: {
    step
  }
});

type SetBlurredI = {
  payload: {
    field: string,
    hasBlurred: boolean
  }
} & ActionI;

export const setBlurred = (
  field: string,
  hasBlurred: boolean
): SetBlurredI => ({
  type: SET_BLURRED,
  payload: {
    field,
    hasBlurred
  }
});

type SetValueI = {
  payload: {
    field: string,
    value: string
  }
} & ActionI;

export const setValue = (field: string, value: string): SetValueI => ({
  type: SET_VALUE,
  payload: {
    field,
    value
  }
});

export const clear = (): ActionI => ({
  type: CLEAR
});

// Initial State
export const STUDENT_INFO_STEP = 0;
export const PAYMENT_INFO_STEP = 1;
export const CONFIRM_ORDER_STEP = 2;
export const ORDER_CONFIRMED_STEP = 3;

export type StateI = {
  step: number,
  isEligibleForInstructorAutomation: boolean,
  curriculumCode: string,
  hasTangibleGoods: boolean,
  willShipTangibleGoods: boolean,
  isRetailPartner: boolean,
  registrationQuestions: Array<RegistrationQuestionI>,
  values: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    shippingAddress1: string,
    shippingAddress2: string,
    shippingCity: string,
    shippingState: string,
    shippingPostalCode: string,
    registrationAnswers: Array<RegistrationAnswerI>,
    confirmAdult?: boolean,
    acceptConsentDisclaimer?: boolean
  },
  fields: {
    firstName: {
      hasBlurred: boolean
    },
    lastName: {
      hasBlurred: boolean
    },
    email: {
      hasBlurred: boolean
    },
    phone: {
      hasBlurred: boolean
    },
    shippingAddress1: {
      hasBlurred: boolean
    },
    shippingAddress2: {
      hasBlurred: boolean
    },
    shippingCity: {
      hasBlurred: boolean
    },
    shippingState: {
      hasBlurred: boolean
    },
    shippingPostalCode: {
      hasBlurred: boolean
    },
    registrationAnswers: {
      hasBlurred: boolean
    }
  },
  hasErrors: boolean,
  errors: {
    firstName?: string,
    lastName?: string,
    email?: string,
    shippingAddress1?: string,
    shippingCity?: string,
    shippingState?: string,
    shippingPostalCode?: string,
    registrationAnswers?: string,
    confirmAdult?: string,
    acceptConsentDisclaimer?: string
  }
};

const initialState: StateI = {
  step: STUDENT_INFO_STEP,
  isEligibleForInstructorAutomation: false,
  curriculumCode: '',
  hasTangibleGoods: false,
  willShipTangibleGoods: false,
  isRetailPartner: false,
  customConfirmation: null,
  values: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingCity: '',
    shippingState: '',
    shippingPostalCode: '',
    registrationAnswers: []
  },
  fields: {
    firstName: {
      hasBlurred: false
    },
    lastName: {
      hasBlurred: false
    },
    email: {
      hasBlurred: false
    },
    phone: {
      hasBlurred: false
    },
    shippingAddress1: {
      hasBlurred: false
    },
    shippingAddress2: {
      hasBlurred: false
    },
    shippingCity: {
      hasBlurred: false
    },
    shippingState: {
      hasBlurred: false
    },
    shippingPostalCode: {
      hasBlurred: false
    },
    registrationAnswers: {
      hasBlurred: false
    }
  },
  registrationQuestions: [],
  hasErrors: true,
  errors: {}
};

// Reducer
const checkout = handleActions(
  {
    [SET_USER]: (state: StateI, action: ReceiveUserReturn): StateI => {
      const { name, surname, email, phone } = action.payload.data.me;
      let values = {
        ...state.values,
        firstName: name,
        lastName: surname,
        email
      };
      // input defaults to zero with empty string, so leave it blank if we don't have a phone number
      if (!!phone) {
        values.phone = phone;
      }

      return {
        ...state,
        values
      };
    },
    [NEXT_STEP]: (state: StateI): StateI => ({
      ...state,
      step: state.step === 2 ? 2 : state.step + 1
    }),
    [PREV_STEP]: (state: StateI): StateI => ({
      ...state,
      step: state.step === 0 ? 0 : state.step - 1
    }),
    [SET_STEP]: (state: StateI, { payload: { step } }: SetStepI): StateI => ({
      ...state,
      step
    }),
    [VALIDATE_FORM]: (state: StateI) => {
      const {
        values,
        isEligibleForInstructorAutomation,
        curriculumCode,
        hasTangibleGoods,
        willShipTangibleGoods,
        registrationQuestions,
        isRetailPartner
      } = state;
      const errors = validate(
        ({
          ...values,
          isEligibleForInstructorAutomation,
          curriculumCode,
          hasTangibleGoods,
          willShipTangibleGoods,
          registrationQuestions,
          isRetailPartner
        }: any)
      );

      return {
        ...state,
        hasErrors: Object.keys(errors).length > 0,
        errors
      };
    },
    [SET_BLURRED]: (
      state: StateI,
      { payload: { field, hasBlurred } }: SetBlurredI
    ): StateI => ({
      ...state,
      fields: {
        ...state.fields,
        [field]: {
          hasBlurred
        }
      }
    }),
    [SET_VALUE]: (
      state: StateI,
      { payload: { field, value } }: SetValueI
    ): StateI => {
      // registration answers
      let newValue = value;
      if (field === 'registrationAnswers') {
        // If using multiple answer in the futer
        // Change newValue to account for multiple answers
        newValue = [value];
      }

      const values = {
        ...state.values,
        [field]: newValue
      };
      return {
        ...state,
        values
      };
    },
    [SET_SOURCE]: (state: StateI): StateI => ({
      ...state,
      step: CONFIRM_ORDER_STEP
    }),
    [BUY_CLASS_SUCCESS]: (state: StateI): StateI => ({
      ...state,
      step: ORDER_CONFIRMED_STEP
    }),
    [REGISTER_SUCCESS]: (state: StateI): StateI => ({
      ...state,
      step: ORDER_CONFIRMED_STEP
    }),
    [SET_COURSE]: (state: StateI, action: SetCourseI): StateI => {
      const { values } = state;
      const entity = parseCourseJsonToEntity(action.payload);
      const {
        hasTangibleGoods,
        curriculumCode,
        willShipTangibleGoods,
        isRetailPartner
      } = entity;
      const addedValues = isRetailPartner
        ? {
            confirmAdult: false,
            acceptConsentDisclaimer: false
          }
        : {};

      return {
        ...state,
        curriculumCode,
        hasTangibleGoods,
        willShipTangibleGoods,
        isRetailPartner,
        values: {
          ...values,
          ...addedValues
        }
      };
    },
    [SET_OCCURRENCE]: (state: StateI, action: SetOccurrenceI): StateI => {
      const { isEligibleForInstructorAutomation, registrationQuestions } =
        action.payload.data.attributes;
      return {
        ...state,
        isEligibleForInstructorAutomation,
        registrationQuestions
      };
    },
    [CLEAR]: (state: StateI): StateI => initialState
  },
  initialState
);

export default checkout;
